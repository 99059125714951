import React, { useRef, useEffect } from 'react';
import styles from '../scss/footer.module.scss';
import cn from 'classnames';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            <section className={cn(styles.coffee)}>
                <div className="container">
                    <h2>Lust auf einen <span>Kaffee?</span></h2>
                    <p>
                        <Link to="/kontakt/" className="btn gold animated" title="Kontakt aufnehmen">Kontakt aufnehmen</Link>
                        <Link to="/konfigurator/" className="btn link" title="Webseite konfigurieren">Webseite konfigurieren</Link>
                    </p>
                </div>
                <svg className={cn(styles.footerLine)} xmlns="http://www.w3.org/2000/svg" width="1479.092" height="452.057" viewBox="0 0 1479.092 452.057">
                    <path id="intro-line" d="M1516.595,0s-28.829,315.03-434.086,310.465-466.3-97.183-744.387-59.354S38,452,38,452" transform="translate(-37.752 0.023)" fill="none" stroke="#cdcdcd" strokeMiterlimit="10" strokeWidth="0.5"/>
                </svg>
            </section>
            <footer className={cn(styles.footerWrapper)}>
                <div className={cn(styles.footerInner)}>
                    <div className="row justify-between">
                        <div className={cn(styles.footerLogo) + ' col'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="41.516" height="18.354" viewBox="0 0 41.516 18.354">
                                <g id="erik-logo" transform="translate(-0.5 -1)">
                                    <path id="Pfad_1" d="M10.333,6.159h-5.9V9.437H9.677V12.5H4.433v3.5h5.9V19.16H.5V3.1h9.833Z" transform="translate(0 0.194)" fill="#fafafa"/>
                                    <path id="Pfad_2" d="M15.733,18.892H11.8V6.109h3.933V8.185A4.778,4.778,0,0,1,19.775,6v4.152H18.683c-1.857,0-2.95.656-2.95,2.841Z" transform="translate(1.046 0.463)" fill="#fafafa"/>
                                    <path id="Pfad_3" d="M20.4,3.185A2.217,2.217,0,0,1,22.694,1a2.136,2.136,0,1,1,0,4.261A2.2,2.2,0,0,1,20.4,3.185Zm.437,3.387H24.77V19.354H20.837Z" transform="translate(1.841)" fill="#fafafa"/>
                                    <path id="Pfad_4" d="M26.9,2.3h3.933v9.4l3.824-5.244h4.807L34.22,12.9l5.353,6.337H34.766l-3.933-5.353v5.353H27.009L26.9,2.3Z" transform="translate(2.443 0.12)" fill="#fafafa"/>
                                </g>
                            </svg>
                            <ul className={cn(styles.footerSocials)}>
                                <li>
                                    <a href="https://www.facebook.com/erik.freelancer" title="Zum Facebook Profil" target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                            <g id="Gruppe_51" transform="translate(-223 -5333.172)">
                                                <circle id="Ellipse_9" cx="13" cy="13" r="13" transform="translate(223 5333.172)" fill="#fafafa"/>
                                                <path id="logo-facebook_8_" d="M46.575,40.637a7.287,7.287,0,1,0-8.426,7.2V42.744H36.3V40.637h1.851V39.032A2.572,2.572,0,0,1,40.9,36.2a11.2,11.2,0,0,1,1.632.142v1.794h-.919a1.054,1.054,0,0,0-1.188,1.139v1.366h2.021l-.323,2.107h-1.7v5.093A7.289,7.289,0,0,0,46.575,40.637Z" transform="translate(196.713 5305.579)" fill="#1f1f1f" fillRule="evenodd"/>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/in/erik-ivanov-721255233/" title="Zum LinkedIn Profil" target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                            <g id="Gruppe_53" transform="translate(-287 -5333.172)">
                                                <circle id="Ellipse_7" cx="13" cy="13" r="13" transform="translate(287 5333.172)" fill="#fafafa"/>
                                                <path id="logo-linkedin" d="M43.916,32H33.107A1.056,1.056,0,0,0,32,33.009V43.842a1.14,1.14,0,0,0,1.107,1.11H43.912a1.082,1.082,0,0,0,1.039-1.11V33.009A.993.993,0,0,0,43.916,32Zm-7.9,10.8H34.159V37.027h1.855Zm-.864-6.646h-.013a1.009,1.009,0,1,1,.013,0ZM42.8,42.8H40.94V39.641c0-.756-.27-1.272-.941-1.272a1.014,1.014,0,0,0-.951.685,1.25,1.25,0,0,0-.064.456V42.8H37.128V37.027h1.855v.8a1.879,1.879,0,0,1,1.673-.938c1.218,0,2.139.8,2.139,2.534Z" transform="translate(261.524 5307.697)" fill="#1f1f1f"/>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className={cn(styles.footerMenuWrapper) + ' col'}>
                            <ul className={cn(styles.footerMenu)}>
                                <li>
                                    <Link to="/impressum/">Impressum</Link>
                                </li>
                                <li>
                                    <Link to="/datenschutz/">Datenschutz</Link>
                                </li>
                            </ul>
                            <span>
                                <small>© { new Date().getFullYear() } by Erik Ivanov</small>
                            </span>
                        </div>
                        <div className={cn(styles.footerContact) + ' col'}>
                            <a href="tel:+4938548597862" className="btn tel gold link" title="Jetzt anrufen">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17.325" height="17.329" viewBox="0 0 17.325 17.329">
                                    <path id="call_1_" d="M45.98,49.338a7.288,7.288,0,0,1-3.4-1.16,22.131,22.131,0,0,1-5.346-4,20.287,20.287,0,0,1-4.015-5.344c-1.425-2.592-1.182-3.95-.911-4.531a3.616,3.616,0,0,1,1.418-1.521,6.819,6.819,0,0,1,1.108-.588l.107-.047a1.035,1.035,0,0,1,.849-.077,2.487,2.487,0,0,1,.807.619,13.41,13.41,0,0,1,2.018,2.995,2.984,2.984,0,0,1,.4,1.227,1.938,1.938,0,0,1-.493,1.153c-.051.069-.1.135-.15.2-.294.387-.359.5-.316.7a7.337,7.337,0,0,0,1.787,2.65,7.033,7.033,0,0,0,2.619,1.743c.208.044.322-.023.721-.328.057-.044.116-.089.178-.134a1.949,1.949,0,0,1,1.17-.524h0a2.8,2.8,0,0,1,1.232.432,14.776,14.776,0,0,1,2.984,2,2.486,2.486,0,0,1,.621.8,1.043,1.043,0,0,1-.077.851c-.014.032-.03.067-.047.106a6.826,6.826,0,0,1-.591,1.105,3.617,3.617,0,0,1-1.523,1.415A2.608,2.608,0,0,1,45.98,49.338Z" transform="translate(-32.098 -32.009)" fill="#dbac52"/>
                                </svg>
                                0385 48597862
                            </a>
                            <a className="btn link white" href="mailto:hallo@erik-ivanov.de" title="Nachricht schreiben">hallo@erik-ivanov.de</a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
 
export default Footer;