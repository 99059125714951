import React, { Component } from 'react';
import styles from '../scss/page.module.scss';
import cn from 'classnames';

class Imprint extends Component {
    render() { 
        return (
            <section className={cn(styles.page)}>
                <div className="small-container">
                    <h1>Impressum</h1>
                    <p>Erik Ivanov<br />
                    Hamburger Allee 64<br />
                    19063 Schwerin</p>
                    <h2>Kontakt</h2>
                    <p>Telefon: 0385 48597862<br />
                    E-Mail: hallo@erik-ivanov.de</p>
                    <h2>Umsatzsteuer-ID</h2>
                    <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                    DE 319862564</p>
                    <h2>EU-Streitschlichtung</h2>
                    <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
                    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                </div>
            </section>
        );
    }
}
 
export default Imprint;